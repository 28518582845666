import React from "react"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"

import {
  Population,
  Features,
  Market,
  Map,
  Tourist,
  Banner,
  District,
} from "page_components/investor"

const Investor = () => {
  const title = "Dla inwestora"

  return (
    <Layout seo={{ title: title }}>
      <Breadcrumbs title={title} />
      <PageHeader title={title} />
      <Population />
      <Features />
      <Market />
      {/* <Map /> */}
      <Tourist />
      <Banner />
      <District />
    </Layout>
  )
}

export default Investor
